<template>
  <div>
      <div class="main-body">

        <div class="row gutters-sm">
          <div class="col-md-4 mb-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex flex-column align-items-center text-center">
                  <img src="https://i.pinimg.com/564x/e8/d7/d0/e8d7d05f392d9c2cf0285ce928fb9f4a.jpg" alt="Admin" class="rounded-circle" width="150">
                  <div class="mt-3">
                    <h4>{{GET_PROFILE_INFO.full_name}}</h4>
                    <p>
                      <span v-for="(item, index) in GET_PROFILE_INFO.roles" :key="index">
                        {{item.name}}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">{{$t('first_name')}}</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{GET_PROFILE_INFO.first_name}}
                  </div>
                </div>

              </div>
              <hr>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">{{$t('last_name')}}</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{GET_PROFILE_INFO.last_name}}
                  </div>
                </div>
              </div>
              <hr>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">{{$t('middle_name')}}</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{GET_PROFILE_INFO.middle_name}}
                  </div>
                </div>
              </div>
              <hr>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">{{$t('iin')}}</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{GET_PROFILE_INFO.iin}}
                  </div>
                </div>
              </div>
              <hr>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">{{$t('email')}}</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {{GET_PROFILE_INFO.email}}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProfilePage",
  computed:{
    ...mapGetters({
      GET_PROFILE_INFO:'user/GET_PROFILE_INFO',
    })
  },

}
</script>

<style lang="scss">
.main-body {
  padding: 15px;
}
.card {
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0,0,0,.125);
  border-radius: .25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.gutters-sm {
  margin-right: -8px;
  margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
  padding-right: 8px;
  padding-left: 8px;
}
.mb-3, .my-3 {
  margin-bottom: 1rem!important;
}

.bg-gray-300 {
  background-color: #e2e8f0;
}
.h-100 {
  height: 100%!important;
}
.shadow-none {
  box-shadow: none!important;
}
</style>